import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

import bookDemoImg from "./bookDemo.png";
import { Link } from "react-router-dom";

const FormPage = ({ data }) => {
  return (
    <section className="request-demo">
      <Container>
        <Row className="pb-5">
          <Col className="col-md-6 col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="request-wrapper">
              <div className="request-heading px-2 py-4">
                <h1 className="title text-white">
                  {/* HybridSync: Bridging LMS and ERP */}
                  {data.bannerTitle}
                </h1>
              </div>
              {data.bannerPoints.map((item, index) => (
                <div key={index} className="request-para p-2 d-flex">
                  <DoneIcon />
                  <p>{item}</p>
                </div>
              ))}
              <div className="request-text p-2">
                <span></span>
              </div>
              <div className="request-story  d-flex align-items-center gap-2">
                <div className="request-g">
                  <img src="" alt="" />
                </div>
                <div className="request-g1">
                  <p>
                    <strong>{data.bannerName}</strong>
                    {data.bannerDesignation}
                  </p>
                </div>
              </div>
              <div className="rating d-flex flex-wrap gap-2 p-2">
                <Box
                  sx={{
                    "& > legend": { mt: 2 },
                  }}
                >
                  <Rating
                    name="read-only"
                    value={parseInt(data.bannerRating)}
                    readOnly
                  />
                </Box>
                <span>Our reviews on </span>
                <img src="" alt="" />
                <img src="" alt="" />
              </div>
              <div className="my-3">
                <Link to={data.bannerButtonNameOneLink}>
                  <Button className="btn-success btn-hover-secondary mx-2">
                    {data.bannerButtonNameOne}
                  </Button>
                </Link>
                <Link to={data.bannerButtonNameTwoLink}>
                  <Button className="btn-primary btn-hover-secondary mx-2">
                    {data.bannerButtonNameTwo}
                  </Button>
                </Link>
              </div>
              {/* <div className="g2-image mt-2">
                <img
                  src=""
                  alt=""
                />
              </div> */}
            </div>
          </Col>
          <Col className="col-md-6 col-12 d-flex align-items-center">
            <div className="img-container">
              <img src={bookDemoImg} alt="" />
            </div>
            {/* <div className="request-form border rounded-4   p-2">
              <div className="form-head border-bottom border-dark p-2">
                <h3 className="fw-bold">Schedule a LIVE demo today </h3>
              </div>
              <div className="p-2 mt-2">
                <form className="request-demo-form">
                  <div className="">
                    <label>
                      Name <span>*</span>
                      <input
                        type="text"
                        name="Name"
                        placeholder="Enter Your Name"
                      />
                    </label>
                  </div>

                  <div className="d-md-flex gap-2">
                    <label>
                      Work Email <span>*</span>
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter Your Email"
                      />
                    </label>
                    <label>
                      Phone Number <span>*</span>
                      <input
                        type="text"
                        name="number"
                        placeholder="eg 7788778"
                      />
                    </label>
                  </div>

                  <div className="d-md-flex gap-2">
                    <label>
                      Job Title <span>*</span>
                      <input
                        type="text"
                        name="jod"
                        placeholder="Enter Your designation"
                      />
                    </label>
                    <label>
                      University/College/Institute/School<span>*</span>
                      <input type="text" name="company" placeholder="Enter here" />
                    </label>
                  </div>

                  <div className="d-md-flex gap-2">
                    <label for="country">
                      country<span>*</span>
                      <input
                        type="text"
                        name="country"
                        placeholder="please select"
                        id="country"
                      />
                    </label>
                    <label className="">
                      Total Monthly Active/Unique Visitors
                      <span>*</span>
                      <input
                        type="text"
                        name="visitor"
                        placeholder="please select"
                      />
                    </label>
                  </div>
                  <div className="text-area d-md-flex gap-2 border-bottom">
                    <label>
                      How did you hear about us?
                      <textarea name="" id="" cols="25" rows="5"></textarea>
                    </label>                    
                  </div>
                  <button className="btn btn-primary btn-hover-success fw-bold mt-2 w-100">
                    Submit
                  </button>
                </form>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FormPage;
