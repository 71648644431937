import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import FooterComponent from "../../components/global/FooterComponent";
import Navbar from "../../components/global/Navbar";

function UserRouter() {
  return (
    <>
      {/* <NavbarComponent /> */}
      <Navbar />
      <ScrollRestoration />
      <Outlet />
      <FooterComponent />
    </>
  );
}

export default UserRouter;
