import React, { useState, useEffect } from "react";
import Loading from "../components/global/Loading";

const DetectDevicePage = () => {
  const [device, setDevice] = useState("");

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.match(/android/)) {
      redirectToPlayStore();
    } else if (userAgent.match(/iphone|ipad|ipod/)) {
      redirectToAppStore();
    } else {
      setDevice("Unknown");
    }
  }, []);

  const redirectToPlayStore = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.cosmosiq.ai";
  };
  const redirectToAppStore = () => {
    window.location.href =
      "https://apps.apple.com/us/app/cosmos-iq/id6480333541";
  };

  return (
    <div>
      <Loading />
    </div>
  );
};

export default DetectDevicePage;
