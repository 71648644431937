import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ETAwards from "../../../assets/website/image/ETBusinessAwardsLogo.png";
import prideAwards from "../../../assets/website/image/Pride-India-Awards-Transparent-Logo.png";

import {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
} from "../../imports/ImageImports";

const images = [
  {
    src: image1,
    alt: "Big Ben - London",
  },
  {
    src: image2,
    alt: "Big Ben - London",
  },
  {
    src: image3,
    alt: "Big Ben - London",
  },

  {
    src: image5,
    alt: "Big Ben - London",
  },
  {
    src: image4,
    alt: "Big Ben - London",
  },
  {
    src: image6,
    alt: "Red Zone - Paris",
  },
  {
    src: image7,
    alt: "Red Zone - Paris",
  },
  {
    src: image14,
    alt: "Red Zone - Paris",
  },
  {
    src: image9,
    alt: "Red Zone - Paris",
  },
  {
    src: image10,
    alt: "Red Zone - Paris",
  },
  {
    src: image11,
    alt: "Red Zone - Paris",
  },
  {
    src: image12,
    alt: "Red Zone - Paris",
  },
  {
    src: image8,
    alt: "Red Zone - Paris",
  },
  {
    src: image13,
    alt: "Red Zone - Paris",
  },
];

const Awards = () => {
  const awardsData = [
    {
      awardId: "2",
      title: "ET Business Award 2023",
      desc: "Proud recipient of the ET Business Award 2023 and featured in the Economic Times.",
      imagePath: ETAwards,
      redirectionURL:
        "https://asiannews.in/presenting-cosmosiq-ai-by-matriye-transforming-the-edtech-universe-in-2024/",
    },
    {
      awardId: "1",
      title: "Awarded in Bangalore!",
      desc: "Indian Iconic Online Learning Platform of the Year 2023",
      imagePath: prideAwards,
      redirectionURL:
        "https://asiannews.in/presenting-cosmosiq-ai-by-matriye-transforming-the-edtech-universe-in-2024/",
    },
  ];

  const [responsive, setResponsive] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive = {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    };

    setResponsive(updatedResponsive);
  }, []);

  const [responsive1, setResponsive1] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive1 = {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    };

    setResponsive1(updatedResponsive1);
  }, []);

  return (
    <>
      <section className="featured-section bg-light">
        <div className="container py-3">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2 className="title">
                  Awards & <span className="text-danger">Accomplishments</span>
                </h2>
                <p className="fs-6 mb-4">
                  As World’s largest higher education company, we have been
                  honoured with several awards.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12">
              <OwlCarousel
                items={2}
                className="owl-theme fetured_slide owl-loaded owl-drag"
                margin={80}
                dots={false}
                responsive={responsive}
              >
                {awardsData
                  ? awardsData.map((award, index) => (
                      <div key={index} className="item">
                        <div className="text-center">
                          <div className="">
                            <img
                              src={award.imagePath}
                              alt={award.imagePath}
                              style={{
                                width: "160px",
                                height: "120px",
                                objectFit: "contain",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                          <h4 className="mt-4 mb-2">{award.title}</h4>
                          <p className="fs-6 fw-medium">{award.desc}</p>
                        </div>
                      </div>
                    ))
                  : ""}
              </OwlCarousel>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12">
              <OwlCarousel
                items={4}
                className="owl-theme fetured_slide owl-loaded owl-drag"
                margin={10}
                dots={true}
                autoplay={true}
                autoplayHoverPause={false}
                autoplayTimeout={2000}
                smartSpeed={1000}
                loop
                responsive={responsive1}
              >
                {images
                  ? images.map((image, index) => (
                      <div key={index} className="item">
                        <div className="text-center">
                          <div className="">
                            <img
                              src={image.src}
                              alt={image.src}
                              style={{
                                // width: "360px",
                                height: "250px",
                                objectFit: "contain",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  : ""}
              </OwlCarousel>
            </div>
          </div>
          {/* <Gallery
            enableImageSelection={false}
            // thumbnailStyle={{}}
            images={images}
          /> */}
        </div>
      </section>
    </>
  );
};

export default Awards;
