import React from "react";
import { Link } from "react-router-dom";

const InfoBlock = ({
  imgUrl,
  subtitle,
  title,
  description,
  buttonsOne,
  buttonsLinkOne,
  buttonsTwo,
  buttonsLinkTwo,
  featureName,
  number,
}) => {
  return (
    <>
      <section className="about-info gray-bg py-4" id="about-info">
        <div className="container">
          <div className="wrapper-info-about">
            <div className=" row align-items-center">
              {number % 2 === 1 ? (
                <div className="col-md-5 pt-4 pb-4">
                  <div className="carousel-image px-2">
                    <img src={imgUrl} alt={title} style={{ height: "100%", width: "100%" }}/>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-7">
                <div className="">
                  <h6 className="fw-normal pb-1" style={{ fontSize: "0.8rem" }}>
                    {title}
                  </h6>
                  <h2 className="title fs-4 carousel-heading fw-semibold text-dark">{subtitle}</h2>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  {buttonsOne ? (
                    <>
                      {window.location.pathname.includes(
                        "/cosmos-iq-business/request-demo"
                      ) ? (
                        <>
                          <Link
                            className="btn btn-primary btn-hover-heading-color"
                            to={buttonsLinkOne}
                            style={{
                              lineHeight: "0px",
                              height: "0",
                              padding: "17px 12px",
                            }}
                          >
                            {buttonsOne}
                          </Link>
                        </>
                      ) : (
                        <>
                          {window.location.pathname.includes(
                            "/teach-on-cosmos-iq"
                          ) ? (
                            <>
                              <Link
                                className="btn btn-primary btn-hover-heading-color"
                                to={buttonsLinkOne}
                                style={{
                                  lineHeight: "0px",
                                  height: "0",
                                  padding: "17px 12px",
                                }}
                              >
                                {buttonsOne}
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                className="btn btn-primary btn-hover-heading-color"
                                to={{
                                  pathname: "/cosmos-iq-business/request-demo",
                                  search: `?featureName=${featureName}`,
                                }}
                                style={{
                                  lineHeight: "0px",
                                  height: "0",
                                  padding: "17px 12px",
                                }}
                              >
                                {buttonsOne}
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {buttonsTwo ? (
                    <Link
                      className="btn btn-outline-primary enterprise-twobtn ms-3"
                      to={buttonsLinkTwo}
                      style={{
                        lineHeight: "0px",
                        height: "0",
                        padding: "17px 12px",
                      }}
                    >
                      {buttonsTwo}
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {number % 2 === 0 ? (
                <div className="col-md-5 pt-4">
                  <div className="carousel-image px-2">
                    <img src={imgUrl} alt={title} style={{ height: "100%", width: "100%" }}/>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InfoBlock;
