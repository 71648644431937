import React from "react";

const AboutUsCeoInfo = ({ data }) => {
  return (
    <>
      <section className="founder bg-light" id="our-ceo">
        <div className="container">
          <div className="row ">
            <div className="col-md-4">
              <div className="founder-image">
                <img src={data.founderPicture} alt="" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="founder-content">
                <h4>{data.founderTitle}</h4>
                <p>{data.founderDescription}</p>
                <h6>{data.founderName}</h6>
                <p className="designation-founder">{data.founderDesignation}</p>
                <div className="founder-social">
                  <a href="https://medium.com/@sanjeev_21777" target="_blank">
                    <i>
                      {" "}
                      <img
                        src="https://www.matriyeacademy.com/website-assets/images/gmail-logo.png"
                        alt=""
                      />
                    </i>
                  </a>
                  <a
                    href="https://www.crunchbase.com/person/kumar-sanjeev-d5d1"
                    target="_blank"
                  >
                    <i>
                      <img
                        src="https://www.matriyeacademy.com/website-assets/images/crunchbase-logo.png"
                        alt=""
                      />
                    </i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/kumar-sanjeev-014809220?original_referer=https%3A%2F%2Fsocial.zoho.in%2F"
                    target="_blank"
                  >
                    <i>
                      <img
                        src="https://www.matriyeacademy.com/website-assets/images/linkedin-logo.png"
                        alt=""
                      />
                    </i>
                  </a>
                  <a
                    href="https://yourstory.com/companies/matriye-academy"
                    target="_blank"
                  >
                    <i>
                      <img
                        src="https://www.matriyeacademy.com/website-assets/images/yourstory-logo.png"
                        alt=""
                      />
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsCeoInfo;
