import React from "react";
import { Link } from "react-router-dom";

const NewFeaturedTopics = (props) => {
  return (
    <>
      <div className="section section-padding featured-topics">
        <div className="container">
          <div className="section-title  row align-items-center justify-content-md-between">
            <div className="col-md-6">
              <h2 className="title">
                <span className="text-danger">Featured topics</span> by
                department
              </h2>
            </div>
            <div className="col-md-6">
              <Link
                to="/filter"
                className="btn btn-primary btn-hover-heading-color"
              >
                View More Course
              </Link>
            </div>
          </div>

          {props.data ? (
            <div className="row">
              {props.data.map((featuredTopics, index) => (
                <div key={index} className="col-xl-3 col-md-6 my-4 px-4">
                  <h4 className="pb-2 fw-bold">
                    {featuredTopics.departmentName}
                  </h4>
                  {featuredTopics.courses
                    ? featuredTopics.courses.map((course, index) => (
                        <div key={index} className="d-flex flex-column ">
                          <Link
                            to={`/filter?q=${course.courseName
                              .toLowerCase()
                              .replace(/\s+/g, "+")}`}
                            className="fw-bold py-1 featureLink"
                            style={{ color: "#405189", fontSize: ".9rem" }}
                          >
                            {course.courseName} &#62;
                          </Link>
                          {/* <span className="text-gray ">
                            {course.totalSubject} subjects
                          </span> */}
                        </div>
                      ))
                    : ""}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default NewFeaturedTopics;
