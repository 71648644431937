import React from "react";
import { Link } from "react-router-dom";
import { FACULTYLINK } from "../../constants";

const infoBlockData = {
  infoImgUrl:
    "https://img.freepik.com/free-photo/computer-security-with-login-password-padlock_107791-16191.jpg?w=900&t=st=1704698828~exp=1704699428~hmac=e9c5c41a145e064f664dec1dc55a24b7518201cde346d8fdddbfebfe5a3bfa3b",
  infoSubtitle: "Cosmos IQ EdTech",
  infoTitle: "Faculty Dashboard",
  infoDescription:
    "Elevate your teaching experience with our intuitive faculty dashboard. Streamline administrative tasks, manage courses effortlessly, and empower student success through insightful analytics. ",
};

const Faculty = () => {
  return (
    <>
      <section className="about-info gray-bg" id="about-info">
        <div className="container">
          <div className="wrapper-info-about">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img
                  src={infoBlockData.infoImgUrl}
                  alt={infoBlockData.infoTitle}
                />
              </div>
              <div className="col-md-7">
                <div className="left-space">
                  <h6>{infoBlockData.infoSubtitle}</h6>
                  <h2>{infoBlockData.infoTitle}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: infoBlockData.infoDescription,
                    }}
                  ></div>
                  <br />

                  <Link
                    to={FACULTYLINK}
                    className="btn btn-primary btn-hover-heading-color"
                  >
                    Access Faculty Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faculty;
