import React from "react";

const BecomeWithComponent = (props) => {
  return (
    <>
      <section className="become-section bg-white">
        <div className="container">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                {/* <h2 className="title">{props.data}</h2> */}
                <h2 className="title">
                  <span className="text-danger">What You Can Become</span> With
                  COSMOS IQ
                </h2>
              </div>
            </div>
          </div>
          {props.dataList ? (
            <div className="row mt-4">
              {props.dataList.map((points, index) => (
                <div key={index} className="col-lg-3 col-md-4">
                  <div className="d-flex flex-column align-items-center bg-light my-3 py-3 becomeCard">
                    <div
                      style={{
                        width: "60px",
                        minWidth: "60px",
                        height: "60px",
                        marginRight: "20px",
                        marginLeft: "10px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundSize: "cover",
                      }}
                    >
                      <img
                        className="lazyloaded"
                        style={{ width: "100%", height: "100%" }}
                        alt={points.title}
                        src={points.imagePath}
                      />
                    </div>
                    <div>
                      <h5 className="text-dark pt-2">{points.title}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
};

export default BecomeWithComponent;
