import React from "react";
import { Link } from "react-router-dom";

const NewTopDepartment = (props) => {
  return (
    <>
      <div className="section section-padding top-department">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">
              <span className="text-danger">Top department</span> you can enroll
              now
            </h2>
          </div>
          <div className="category-wrapper">
            {props.data ? (
              <div className="row">
                {props.data.map((departments, index) => (
                  <div key={index} className="col-lg-3 col-sm-6">
                    <Link
                      to={"/department/" + departments.departmentNameSlugUrl}
                      className="d-flex align-items-center bg-light my-2 topDepartmentCard"
                    >
                      <div
                        className=""
                        style={{
                          width: "100px",
                          minWidth: "80px",
                          height: "80px",
                          marginRight: "20px",
                          backgroundSize: "cover",
                        }}
                      >
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={departments.topRatedDepartmentImage}
                          alt={departments.departmentName}
                        />
                      </div>
                      <div>
                        <h5> {departments.departmentName}</h5>
                        <p>{departments.totalCourse} Course</p>
                      </div>
                    </Link>
                    {/* <div className="single-category flex-fill">
                      <div className="category-image">
                        <Link
                          to={
                            "/department/" + departments.departmentNameSlugUrl
                          }
                        >
                          <img
                            src={departments.topRatedDepartmentImage}
                            alt={departments.departmentName}
                          />
                        </Link>
                      </div>
                      <div className="category-content">
                        <div className="category-title">
                          <h4 className="title">
                            <Link
                              to={
                                "/department/" +
                                departments.departmentNameSlugUrl
                              }
                              className="link-title"
                            >
                              {departments.departmentName}
                            </Link>
                          </h4>
                          <p>{departments.totalCourse} Course</p>
                        </div>
                        <Link
                          to={
                            "/department/" + departments.departmentNameSlugUrl
                          }
                          className="category-link"
                          aria-label="category-link"
                        >
                          <i className="fa fa-long-arrow-right"></i>
                        </Link>
                      </div>
                    </div> */}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTopDepartment;
