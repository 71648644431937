import React, { useEffect, useState } from "react";
import "./AvailableBatches.css";
import { Link, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Pagination from "./pagination";
import axios from "axios";
import { toast } from "react-toastify";
import NoRecordFound from "../components/core/no-record-found/NoRecordFound";
import { FACULTYLINK, STUDENTLINK } from "../constants";
import Cookies from "js-cookie";

const AvailableBatches = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);

  const [subjectDetails, setSubjectDetails] = useState([]);
  const [batchesList, setBatchesList] = useState([]);

  const { courseNameSlugUrl } = useParams();
  const { subjectNameSlugUrl } = useParams();

  const formData = new FormData();
  formData.append("courseNameSlugUrl", courseNameSlugUrl);
  formData.append("subjectNameSlugUrl", subjectNameSlugUrl);

  const stringExists = Cookies.get("_iamcosmosfdty")
    ? Cookies.get("_iamcosmosfdty").includes("instructor")
    : null;

  useEffect(() => {
    if (courseNameSlugUrl || subjectNameSlugUrl) {
      getAvailableBatchesData();
      getSubjectDetailsData();
    }
  }, [courseNameSlugUrl]);

  const getSubjectDetailsData = async () => {
    let headers = {
      "Content-Type": "multipart/form-data",
    };

    await axios
      .post(window.apiBaseUrl + "subject/get-subject-details", formData, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setSubjectDetails(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getAvailableBatchesData = async () => {
    let headers = {
      "Content-Type": "multipart/form-data",
    };

    await axios
      .post(window.apiBaseUrl + "subject/available/batches", formData, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setBatchesList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const batchesData = batchesList.list
    ? batchesList.list.slice(startIdx, endIdx)
    : [];

  const totalPages = Math.ceil(batchesList.totalRecord / itemsPerPage);

  return (
    <>
      <div className="container">
        <div className="backButtonContainer" style={{ marginTop: "100px" }}>
          <Link
            to={`/courses/${subjectDetails.courseNameSlugUrl}/${subjectDetails.subjectNameSlugUrl}`}
            className="btn btn-primary btn-hover-heading-color"
          >
            <i className="fa fa-chevron-left" style={{ color: "#888b88" }}></i>
            &nbsp; Go back
          </Link>
          <h2 className="title pt-3 pb-2">
            <span className="text-danger">Schedules for</span>{" "}
            {subjectDetails.courseName} - {subjectDetails.subjectName}
          </h2>
          <ul className="badges-container d-flex gap-2 flex-wrap">
            <li className="batchesBadge">
              <i
                className="fa fa-bar-chart pe-1"
                style={{ color: "#888b88" }}
              ></i>{" "}
              Engagement
            </li>
            <li className="batchesBadge">
              <i className="fa fa-tasks pe-1" style={{ color: "#888b88" }}></i>{" "}
              Progress Tracking
            </li>
            <li className="batchesBadge">
              <i className="fa fa-book pe-1" style={{ color: "#888b88" }}></i>{" "}
              Multiple Choice Questions (MCQs)
            </li>
            <li className="batchesBadge">
              <i
                className="fa fa-pencil-square-o pe-1"
                style={{ color: "#888b88" }}
              ></i>{" "}
              Short Answer Questions
            </li>
            <li className="batchesBadge">
              <i
                className="fa fa-clock-o pe-1"
                style={{ color: "#888b88" }}
              ></i>{" "}
              Timed Quizzes
            </li>
          </ul>
          <div className="filters-wrap">
            <h4>
              {(batchesList && batchesList.totalRecord) || 0} schedules
              Available
            </h4>
            <div className="filter-list">
              <ul className="d-flex">
                <li>Weekday</li>
                <li>Weekend</li>
                <li>Morning (06:00 to 16:00)</li>
                <li>Evening (16:00 to 06:00)</li>
                <select
                  name=""
                  id=""
                  style={{
                    width: "max-content",
                    padding: "6px 16px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333",
                  }}
                >
                  <option value="">Select Month</option>
                  <option value="">None</option>
                  <option value="">April</option>
                  <option value="">May</option>
                </select>
              </ul>
            </div>
          </div>
          <div className="batch-cards">
            <ul>
              <Row className="mb-4 gap-4">
                {batchesData.length > 0 ? (
                  <>
                    {batchesData.map((filter, index) => (
                      <Col
                        key={index}
                        lg={5}
                        className="info-wrap d-flex align-items-center justify-content-between px-3"
                      >
                        <div className="info">
                          <h4>{filter.batchName}</h4>
                          <h6 className="time pt-3 fw-medium ">
                            <i
                              className="fa fa-calendar pe-1"
                              style={{ color: "#888b88" }}
                            ></i>{" "}
                            {filter.fromDate} - {filter.toDate}
                          </h6>
                          <h6 className="time pt-2 fw-medium ">
                            <i
                              className="fa fa-clock-o pe-1"
                              style={{ color: "#888b88" }}
                            ></i>{" "}
                            Timing {filter.startTime} - {filter.endTime}
                          </h6>
                          <h6 className="instructorName pt-2 fw-medium ">
                            <i
                              className="fa fa-user pe-1"
                              style={{ color: "#888b88" }}
                            ></i>{" "}
                            {filter.fullName}
                          </h6>
                        </div>
                        <div className="enroll">
                          {stringExists ? (
                            <Link
                              to={
                                FACULTYLINK +
                                "/course/batches/" +
                                subjectDetails.decodeSubjectId
                              }
                              className="btn btn-success"
                            >
                              Enroll now
                            </Link>
                          ) : (
                            <>
                              {Cookies.get("_iamcosmosudty") ? (
                                <Link
                                  to={
                                    STUDENTLINK +
                                    "/course/batches/" +
                                    subjectDetails.decodeSubjectId
                                  }
                                  className="btn btn-success"
                                >
                                  Enroll now
                                </Link>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                    ))}
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onPageChange={setCurrentPage}
                    />
                  </>
                ) : (
                  <NoRecordFound
                    link="/filter"
                    buttonName="Explore our Courses"
                  />
                )}
              </Row>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableBatches;
