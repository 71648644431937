import React, { useEffect, useState } from "react";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import Loading from "../../components/global/Loading";

const Cookies = () => {
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTermsData();
  }, []);

  const formData = new FormData();
  formData.append("pageName", "cookies-policy");

  const getTermsData = async () => {
    setIsLoading(true);
    await axios
      .post(
        window.apiBaseUrl + "policies-terms-and-condition/policy-details",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTermsAndConditions(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>
          {termsAndConditions.metaTitle
            ? termsAndConditions.metaTitle
            : SITETITLE}
        </title>
        <meta
          name="description"
          content={
            termsAndConditions.metaDescription
              ? termsAndConditions.metaDescription
              : SITETITLE
          }
        />
        <meta
          name="keywords"
          content={
            termsAndConditions.metaKeywords
              ? termsAndConditions.metaKeywords
              : SITETITLE
          }
        />
      </Helmet>
      {termsAndConditions ? (
        <HeaderBanner
          imgUrl={termsAndConditions.bannerImage}
          subtitle={termsAndConditions.bannerHeading}
          title={termsAndConditions.bannerSubHeading}
          description={termsAndConditions.bannerDescription}
          buttonsOne={termsAndConditions.buttonNameOne}
          buttonsLinkOne={termsAndConditions.buttonLinkOne}
          buttonsTwo={termsAndConditions.buttonNameTwo}
          buttonsLinkTwo={termsAndConditions.buttonLinkTwo}
        />
      ) : (
        ""
      )}
      <section className="about-us-trial">
        <div className="container">
          <div className="row">
            <div className="col-md-12 res-m-bttm text-left">
              <h2 className="heading-section">
                {termsAndConditions.bannerSubHeading}
              </h2>
            </div>
            <div className="col-md-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: termsAndConditions.sectionDescription,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cookies;
