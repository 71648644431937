import React from "react";
import { Link } from "react-router-dom";

const ThreeBoxLayout = ({
  backgroundImage,
  pageTitle,
  pageSubTitle,
  boxContent,
  customId,
}) => {
  return (
    <>
      <section
        className="page-title-section section-department"
        // style={{ backgroundImage: `url(${backgroundImage})` }}
        style={{ backgroundColor: "#405189" }}
        id={customId}
      >
        <div className="auto-container" id="section-department">
          <div className="content-box">
            <div className="text">{pageSubTitle}</div>
            <h2 className="title text-danger">{pageTitle}</h2>
          </div>
        </div>
      </section>
      <section className="faq-blocks-section">
        <div className="container">
          <div className="inner-container">
            {boxContent ? (
              <>
                <div className="row clearfix">
                  {boxContent.map((department) => (
                    <div
                      key={department.sectionId}
                      className="faq-block col-lg-4 col-md-6 col-sm-12 d-flex"
                    >
                      <div
                        className="inner-box wow fadeInLeft flex-fill bg-light"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="gift_image">
                          {department.sectionImage ? (
                            <>
                              <img
                                className="lazyloaded"
                                data-src={department.sectionImage}
                                alt="department"
                                loading="lazy"
                                src={department.sectionImage}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <h4 className="pb-2 fs-5 fw-semibold">
                          {department.sectionName ? (
                            <Link to={department.link}>
                              {department.sectionName}
                            </Link>
                          ) : (
                            ""
                          )}
                        </h4>
                        <div className="text_faq">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: department.sectionDescription,
                            }}
                          ></p>
                        </div>
                        {department.link ? (
                          <>
                            <Link to={department.link} className="read-more">
                              View More
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ThreeBoxLayout;
