import { Container, Row, Col } from "react-bootstrap";
import Table from "./Cards/CollapsibleTable";
const CompareFeature = ({ planPricing, countryDetails, plan }) => {
  return (
    <section>
      <Container className="mt-4 mb-4">
        <Row>
          <Col className="text-center mt-4 mb-4">
            <h1>Compare All Features </h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Table
          planPricing={planPricing.list}
          countryDetails={countryDetails}
          plan={plan}
        />
      </Container>
    </section>
  );
};

export default CompareFeature;
