import { Container, Row, Col } from "react-bootstrap";
import Img from "../../assets/image_copy/lms1.png";
import { Link } from "react-router-dom";
const Stories = ({ staticDetails }) => {
  return (
    <section className="story-section position-relative p-4">
      <Container className="story-section-container">
        <Row style={{ backgroundColor: "#00031F" }}>
          <Col
            style={{ backgroundColor: "#B5D0F8" }}
            className="story  col-md-4 col-12"
          >
            <div className="d-flex justify-content-center pt-2">
              <img
                className="story-img"
                src={
                  staticDetails.sectionTwoImage
                    ? staticDetails.sectionTwoImage
                    : Img
                }
                alt="storyImage"
              />
            </div>
          </Col>
          <Col className="col-md-7 col-12 p-5  d-flex align-items-end ">
            <div className="story-text ">
              {/* <h2 className="text-white fs-4 px-md-4 pb-3">
                Revolutionize education delivery
              </h2> */}
              <p className=" fw-normal text-white px-md-4 mb-2">
                {staticDetails.sectionTwoDescription
                  ? staticDetails.sectionTwoDescription
                  : "N/A"}
              </p>
              <h6 className="text-white fs-5 px-md-4 pb-3">
                {staticDetails.sectionTwoNameAndDesignation
                  ? staticDetails.sectionTwoNameAndDesignation
                  : "N/A"}
              </h6>
              <Link
                to={
                  staticDetails.sectionTwoButtonLink
                    ? staticDetails.sectionTwoButtonLink
                    : "https://www.google.com"
                }
                className="btn btn-success btn-hover-primary m-md-4 mt-4 fw-medium"
              >
                {staticDetails.sectionTwoButtonName
                  ? staticDetails.sectionTwoButtonName
                  : "N/A"}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Stories;
