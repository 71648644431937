import * as React from "react";
// import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VariableWidth from "./ToolTip";
import { FeaturesData } from "./CompareFeaturesData";
import { isEmpty } from "lodash";

export default function CollapsibleTable({
  planPricing,
  countryDetails,
  plan,
}) {
  const [openStates, setOpenStates] = React.useState(
    Array(FeaturesData.length).fill(false)
  );

  const [currencySymbol, setCurrencySymbol] = React.useState("₹");
  React.useEffect(() => {
    if (!isEmpty(countryDetails)) {
      setCurrencySymbol(countryDetails.currencySymbol);
    }
  }, [countryDetails]);

  const handleOpen = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  const filteredPlans = planPricing
    ? planPricing.filter((plan) => plan.planName !== "Basic")
    : null;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {filteredPlans &&
              filteredPlans.map((item, index) => (
                <TableCell align="center" key={index + 1}>
                  <h5 className="text-primary">{item.planName}</h5>
                  <p>
                    {currencySymbol}
                    {countryDetails && countryDetails.currencySymbol !== "₹" ? (
                      <>
                        {plan === "year"
                          ? (
                              parseInt(item.annuallyPrice) /
                              countryDetails.value
                            ).toFixed(2)
                          : (
                              parseInt(item.monthlyPrice) / countryDetails.value
                            ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {plan === "year"
                          ? item.annuallyPrice
                          : item.monthlyPrice}
                      </>
                    )}
                    <span>/{plan}</span>
                  </p>
                  <button className="btn btn-primary">Buy Now</button>
                </TableCell>
              ))}

            {/* <TableCell align="center">
              <h5 className="text-primary">Professional</h5>
              <p>
                14,999<span>/Y</span>
              </p>
              <button className="btn btn-primary btn-hover-success ">
                Buy Now
              </button>
            </TableCell>
            <TableCell align="center">
              <h5 className="text-primary">Preminum</h5>
              <p>
                24,999<span>/Y</span>
              </p>
              <button className="btn btn-primary btn-hover-success ">
                Buy Now
              </button>
            </TableCell>
            <TableCell align="center">
              <h5 className="text-primary">Elite</h5>
              <p>
                49,999 <span>/Y</span>
              </p>
              <button className="btn btn-outline-primary ">Buy Now</button>
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {FeaturesData.map((item, index) => (
            <React.Fragment key={index + 1}>
              <TableRow
                sx={{
                  "&:last-child": {
                    borderBottom: "1px solid #e0e0e0", // Add border to the last child
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: "none",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 className="text-primary fw-bold">
                    {item.mainFeatureName}
                  </h4>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleOpen(index)}
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {openStates[index] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                {/* <TableCell>
                 
                </TableCell> */}
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: "none",
                    backgroundColor: "#f0f0f0",
                    textAlign: "center",
                  }}
                >
                  Yes
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: "none",
                    backgroundColor: "#f0f0f0",
                    textAlign: "center",
                  }}
                >
                  Yes
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: "none",
                    backgroundColor: "#f0f0f0",
                    textAlign: "center",
                  }}
                >
                  Yes
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: "none",
                    backgroundColor: "#f0f0f0",
                    textAlign: "center",
                  }}
                >
                  Yes
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={openStates[index]} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        {item && item.features ? (
                          <TableBody>
                            {item.features.map((feature, index) => (
                              <TableRow
                                key={feature.feature_name}
                                className="d-flex justify-content-between"
                              >
                                <TableCell
                                  // className="w-25"
                                  width="28%"
                                  component="th"
                                  scope="row"
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    {feature.feature_name}
                                    <VariableWidth infoTip={feature.infoTip} />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    // textAlign: "center",
                                    paddingBottom: "2rem",
                                    paddingLeft: "40px",
                                  }}
                                >
                                  {feature.isAssignStandard}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    // textAlign: "center",
                                    paddingBottom: "2rem",
                                  }}
                                >
                                  {feature.isAssignProfessional}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    // textAlign: "center",
                                    paddingBottom: "2rem",
                                  }}
                                >
                                  {feature.isAssignPreminum}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    // textAlign: "center",
                                    paddingBottom: "2rem",
                                    paddingRight: "65px",
                                  }}
                                >
                                  {feature.isAssignElite}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          ""
                        )}
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
