// import { useFormik } from "formik";
import React from "react";
// import { forReplyBlogComment } from "../../config/formValidation";
// import axios from "axios";
// import { toast } from "react-toastify";

const BlogCommentReply = (props) => {
  // const initialValues = {
  //   blogCommentId: "", // Add blogCommentId to initialValues
  //   commentReply: "",
  // };

  // const {
  //   values,
  //   errors,
  //   isSubmitting,
  //   touched,
  //   handleBlur,
  //   handleChange,
  //   handleSubmit,
  // } = useFormik({
  //   enableReinitialize: true,
  //   initialValues,
  //   // validationSchema: forReplyBlogComment,
  //   onSubmit: async (values, action) => {
  //     try {
  //       console.log("values", values);
  //       return false;
  //       // Send the blogCommentId along with the commentReply data
  //       const formData = new FormData();
  //       formData.append('blogCommentId', values.blogCommentId);
  //       formData.append('commentReply', values.commentReply);
  //       await axios.post(window.apiBaseUrl + "blog/blog-comment-reply", formData, {
  //         headers: {
  //           AccessToken: "Bearer " + localStorage.getItem("authUser"),
  //           "Content-Type": "multipart/form-data"
  //         },
  //       });
  //       toast.success("Reply submitted successfully!", {
  //         autoClose: 5000,
  //         theme: "colored",
  //       });
  //       action.resetForm();
  //     } catch (err) {
  //       toast.error(err.response.data.message, {
  //         autoClose: 5000,
  //         theme: "colored",
  //       });
  //     }
  //   },
  // });

  return (
    <>
      <ul className="comment-items">
        {props.commentList.map((comment, index) => (
          <li key={index}>
            <div className="single-comment">
              <div
                className="comment-author"
                style={{ width: "50px", height: "50px" }}
              >
                <img
                  src={comment.profilePicture}
                  alt={comment.commentByName}
                  loading="lazy"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="comment-content">
                <h4 className="name">{comment.commentByName}</h4>
                <p>{comment.commentDescrption}</p>
                <div className="meta">
                  <div className="like-buton">
                    {comment.likeCount > 0 ? (
                      <b>{comment.likeCount}&nbsp;</b>
                    ) : (
                      ""
                    )}
                    {comment.likeStatus ? (
                      <span data-target="#login" data-toggle="modal">
                        {comment.likeStatus > 0 ? (
                          <i
                            className="fa  fa-thumbs-up"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-thumbs-o-up"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;Likes
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <a
                    className="reply collapsed"
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseOne${comment.blogCommentId}`}
                    aria-expanded="false"
                    aria-controls={`collapseOne${comment.blogCommentId}`}
                  >
                    {comment.replyCount > 0 ? <b>{comment.replyCount}&nbsp;</b> : ""}
                    <i className="fa fa-comment"></i> Reply
                  </a> */}
                  <span className="date text-right">{comment.postedAt}</span>
                  {/* <div className="accordion" id={`accordionExample${comment.blogCommentId}`}>
                    <div className="accordion-item border-none">
                      <div
                        id={`collapseOne${comment.blogCommentId}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent={`#accordionExample${comment.blogCommentId}`}
                      >
                        <div className="accordion-body">
                          <div className="contact-form">
                            <form autoComplete="off" acceptCharset="utf-8" noValidate="novalidate" onSubmit={handleSubmit}>                              
                              <input type="hidden" name="blogCommentId" value={comment.blogCommentId} />
                              <div className="form-group">
                                <textarea
                                  name={`commentReply-${comment.blogCommentId}`}
                                  placeholder="Comment"
                                  value={values[`commentReply-${comment.blogCommentId}`] || ""}
                                  onChange={(e) => handleChange(e, comment.blogCommentId)}
                                  onBlur={(e) => handleBlur(e, comment.blogCommentId)}
                                ></textarea>
                                {errors[`commentReply-${comment.blogCommentId}`] &&
                                  touched[`commentReply-${comment.blogCommentId}`] && (
                                    <p className="text-danger">
                                      {errors[`commentReply-${comment.blogCommentId}`]}
                                    </p>
                                  )}
                              </div>
                              <div className="form-group mb-0">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary text-white btn-hover-heading-color">
                                  {isSubmitting ? "Please wait..." : "Reply"}
                                </button>
                              </div>
                            </form>
                          </div>
                          {comment.commentReply && comment.commentReply.length > 0 && (
                            <div>
                              {comment.commentReply.map((reply, replyIndex) => (
                                <div key={replyIndex} className="single-comment">
                                  <div className="comment-author">
                                    <img src={reply.profilePicture} alt={reply.fullName} loading="lazy" />
                                  </div>
                                  <div className="comment-content">
                                    <h4 className="name">{reply.fullName}</h4>
                                    <p>{reply.commentReply}</p>
                                    <div className="meta">
                                      <span className="date">{reply.postedAt}</span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default BlogCommentReply;
