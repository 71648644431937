import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Card, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SITETITLE } from "../constants";
import { useCart } from "react-use-cart";
import Loading from "../components/global/Loading";
import VideoModal from "./VideoModal";
import instructorPicture from "../assets/website/image/instructorPicture.jpg";
import instructorCompany from "../assets/website/image/meta-logo.png";
import instructorLinkedIn from "../assets/website/image/linkedin-logo.png";
import imgPlaceholder from "../assets/website/image/placeholder.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import data from "./data.json";
import CourseVideoModal from "./CourseVideoModal";
import { isEmpty } from "lodash";
import Cookies from "js-cookie";

let accessToken = null;

if (!isEmpty(Cookies.get("_iamcosmosud"))) {
  accessToken = Cookies.get("_iamcosmosud");
} else if (!isEmpty(Cookies.get("_iamcosmosfd"))) {
  accessToken = Cookies.get("_iamcosmosfd");
} else if (!isEmpty(Cookies.get("_iamcosmosed"))) {
  accessToken = Cookies.get("_iamcosmosed");
}

const getStartLearningData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "subject/start-learning-today",
      { signal }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const ERPCourseDetails = () => {
  const TruncatedParagraph = ({ text }) => {
    // Split the text into an array of words
    const words = text ? text.split(" ") : "";

    // Check if the number of words exceeds 150
    if (!isEmpty(words) && words.length > 150) {
      // Slice the array to get the first 150 words and join them back into a string
      const truncatedText = words.slice(0, 30).join(" ");

      // Add "..." at the end of the truncated text
      const displayText = truncatedText + "...";

      return <>{displayText}</>;
    }
    // If the text is 150 words or fewer, display it as is
    return <>{text}</>;
  };

  const [responsive, setResponsive] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  });

  const [responsive1, setResponsive1] = useState({
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive = {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    };

    setResponsive(updatedResponsive);
  }, []);

  const [isSticky, setIsSticky] = useState(false);
  const [isLoadingLocal, setIsLoadingLocal] = useState(true);

  const {
    data: startLearningData,
    // error,
    isLoading: isLoadingQuery, //Added for Loading
  } = useQuery("startLearningData", getStartLearningData, {
    staleTime: 60000, // Cache data for 60 seconds
    cacheTime: 300000, // Cache data for 5 minutes
    retry: 1, // Retry the request if it fails
    refetchOnWindowFocus: false, // Disable refetching on window focus
    onError: (err) => {
      if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
      } else {
        console.error(err);
      }
    },
  });

  const { courseNameSlugUrl } = useParams();
  const { subjectNameSlugUrl } = useParams();

  const [subjectId, setSubjectId] = useState("");
  const [subjectDetails, setSubjectDetails] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [relatedSubjectList, setRelatedSubjectList] = useState([]);
  const [subjectStructureList, setSubjectStructureList] = useState([]);
  const [subjectInstructorList, setSubjectInstructorList] = useState([]);
  const [subjectVideosList, setSubjectVideosList] = useState([]);
  const [subjectFaqsList, setSubjectFaqsList] = useState([]);
  const [subjectReviewRatingList, setSubjectReviewRatingList] = useState([]);

  const navigate = useNavigate();

  // Check if the string exists within the component
  const stringExists = Cookies.get("_iamcosmosfdty")
    ? Cookies.get("_iamcosmosfdty").includes("instructor")
    : null;

  useEffect(() => {
    setIsLoadingLocal(true);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const targetElement = document.getElementById("scrollTriggerId");

      if (targetElement) {
        const targetPosition = targetElement.offsetTop;
        const isStickyNow = scrollPosition >= targetPosition;

        setIsSticky(isStickyNow);
      }
    };

    //Added for Loading

    const fetchData = async () => {
      try {
        await getSubjectDetailsData();
        // await Promise.all([
        // getContentListData(subjectId),
        // getRelatedSubjectListData(subjectId),
        // getSubjectStructureData(subjectId),
        // getSubjectInstructorData(subjectId),
        // getSubjectInstructorData(subjectId),
        // getSubjectFaqsListData(subjectId),
        // getSubjectReviewRatingListData(subjectId),
        // ]);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      } finally {
        setIsLoadingLocal(false);
      }
    };

    //End of Added for Loading

    if (courseNameSlugUrl || subjectNameSlugUrl) {
      getSubjectDetailsData();
      fetchData(); //Added for Loading
    }

    if (subjectId) {
      getContentListData(subjectId);
      getRelatedSubjectListData(subjectId);
      getSubjectStructureData(subjectId);
      getSubjectInstructorData(subjectId);
      getSubjectVideosData(subjectId);
      getSubjectFaqsListData(subjectId);
      getSubjectReviewRatingListData(subjectId);
    }

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [subjectId, courseNameSlugUrl, subjectNameSlugUrl]);

  const stickyClass = isSticky ? "courseNavFixed" : "";

  const formData = new FormData();
  formData.append("courseNameSlugUrl", courseNameSlugUrl);
  formData.append("subjectNameSlugUrl", subjectNameSlugUrl);

  const getSubjectDetailsData = async () => {
    let headers = {
      "Content-Type": "multipart/form-data",
    };

    // Check if authUser exists in local storage
    // const authUser = localStorage.getItem("authUser");
    if (accessToken) {
      headers.AccessToken = "Bearer " + accessToken;
    }

    await axios
      .post(window.apiBaseUrl + "subject/get-subject-details", formData, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setSubjectDetails(res.data.data);
          setSubjectId(res.data.data.decodeSubjectId);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        navigate("/");
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getContentListData = async (val) => {
    await axios
      .post(
        window.apiBaseUrl + "subject/content-list",
        { subjectId: val },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setContentList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getRelatedSubjectListData = async (val) => {
    await axios
      .post(
        window.apiBaseUrl + "subject/related-subject-list",
        { subjectId: val },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setRelatedSubjectList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getSubjectStructureData = async (val) => {
    await axios
      .post(
        window.apiBaseUrl + "subject/subject-structure",
        { subjectId: val },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSubjectStructureList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getSubjectInstructorData = async (val) => {
    await axios
      .post(
        window.apiBaseUrl + "subject/subject-instructor",
        { subjectId: val },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSubjectInstructorList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getSubjectVideosData = async (val) => {
    await axios
      .post(
        window.apiBaseUrl + "subject/subject-videos",
        { subjectId: val },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSubjectVideosList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getSubjectFaqsListData = async (val) => {
    await axios
      .post(
        window.apiBaseUrl + "subject/subject-faqs",
        { subjectId: val },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSubjectFaqsList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getSubjectReviewRatingListData = async (val) => {
    await axios
      .post(
        window.apiBaseUrl + "subject/rating-review-list",
        { subjectId: val },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSubjectReviewRatingList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const { addItem, inCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: subjectDetails.decodeSubjectId,
      subjectName: subjectDetails.subjectName,
      courseName: subjectDetails.courseName,
      image: subjectDetails.subjectPicture,
      price: subjectDetails.subjectPrice,
    };
    addItem(product);
  };

  const handleBuyItNow = () => {
    const product = {
      id: subjectDetails.decodeSubjectId,
      subjectName: subjectDetails.subjectName,
      courseName: subjectDetails.courseName,
      image: subjectDetails.subjectPicture,
      price: subjectDetails.subjectPrice,
    };
    addItem(product);
    navigate("/cart");
  };

  const handleEnrollNow = async () => {
    const para = {
      subjectId: subjectId,
    };
    await axios
      .post(window.apiBaseUrl + "subject/enroll", para, {
        headers: {
          AccessToken: "Bearer " + accessToken,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        } else {
          // toast.error(res.data.message, {
          //   autoClose: 5000,
          //   theme: "colored",
          // });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          autoClose: 5000,
          theme: "colored",
        });
      });
  };

  const [showModal, setShowModal] = useState(false);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [cardVideoLink, setCardVideoLink] = useState(null);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const openCourseModal = (link) => {
    // console.log(link);
    setShowCourseModal(true);
    setCardVideoLink(link);
  };

  const closeCourseModal = () => {
    setShowCourseModal(false);
  };

  if (isLoadingLocal || isLoadingQuery) return <Loading />; //Added for Loading
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>
          {subjectDetails.metaTitle ? subjectDetails.metaTitle : SITETITLE}
        </title>
        <meta
          name="description"
          content={
            subjectDetails.metaDescription
              ? subjectDetails.metaDescription
              : SITETITLE
          }
        />
        <meta
          name="keywords"
          content={
            subjectDetails.metaKeywords
              ? subjectDetails.metaKeywords
              : SITETITLE
          }
        />
      </Helmet>
      {subjectDetails.subjectPicture && subjectDetails.subjectName ? (
        <div
          className="section page-banner-section bg-color-1 course_detail_banner my-0 py-3"
          style={{
            backgroundImage: `url(${subjectDetails.subjectPicture})`,
          }}
        >
          <div className="container pb-5 pt-2">
            <span style={{ fontSize: ".9rem", color: "gray" }}>
              {subjectDetails.categoryName} &gt; {subjectDetails.departmentName}{" "}
              &gt; {subjectDetails.courseName}
            </span>
            <div
              className="logo-container py-4"
              // style={{ width: "350px", height: "120px" }}
            >
              <img
                src={subjectDetails.creatorImage}
                alt="creatorImage"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div className="course-details-banner-content">
              <h1 className="title">{subjectDetails.subjectName} </h1>
              <div className="detail_category_part">
                <p className="detail_category">
                  {subjectDetails.categoryName} |
                </p>
                <p className="detail_category">{subjectDetails.courseName} </p>
              </div>
              {/* <p>{subjectDetails.subjectLongDescription}</p> */}
              <p className="pb-5">{subjectDetails.brandName}</p>
              {/* <div className="course-details-meta">
                {subjectDetails.creatorName ? (
                  <div className="meta-action">
                    <div className="meta-author">
                      <img
                        src={subjectDetails.creatorImage}
                        alt={subjectDetails.creatorName}
                        loading="lazy"
                      />
                    </div>
                    <div className="meta-name">
                      <h5 className="name">{subjectDetails.creatorName}</h5>
                    </div>
                    <div className="meta-action">
                      <p className="label">
                        Last Update :
                        <span>{subjectDetails.lastUpdatedDate}</span>
                      </p>
                    </div>
                    <div className="rating">
                      <div className="rating-star">
                        <div
                          className="rating-active"
                          style={{
                            width: `${Math.round(
                              subjectDetails.subjectRating * 2
                            )}0%`,
                          }}
                        ></div>
                      </div>
                      <span>({subjectDetails.subjectRating})</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <nav className={`container ${stickyClass}`}>
        <ul className="courseNav" id="" role="tablist">
          <li
            className="courseNavink nav-link active"
            id="nav-overview-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-overview"
            role="tab"
            aria-controls="nav-overview"
            aria-selected="true"
          >
            Overview
          </li>
          <li
            className="courseNavink nav-link"
            id="nav-admission-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-admission"
            role="tab"
            aria-controls="nav-admission"
            aria-selected="false"
          >
            Admission
          </li>
          <li
            className="courseNavink nav-link"
            id="nav-academics-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-academics"
            role="tab"
            aria-controls="nav-academics"
            aria-selected="false"
          >
            Academics
          </li>
          <li
            className="courseNavink nav-link"
            id="nav-tution-financing-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-tution-financing"
            role="tab"
            aria-controls="nav-tution-financing"
            aria-selected="false"
          >
            Tution and Financing
          </li>
          <li
            className="courseNavink nav-link"
            id="nav-student-experience-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-student-experience"
            role="tab"
            aria-controls="nav-student-experience"
            aria-selected="false"
          >
            Student Experience
          </li>
          <li
            className="courseNavink nav-link"
            id="nav-university-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-university"
            role="tab"
            aria-controls="nav-university"
            aria-selected="false"
          >
            About the University
          </li>
          <li
            className="courseNavink nav-link"
            id="nav-careers-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-careers"
            role="tab"
            aria-controls="nav-careers"
            aria-selected="false"
          >
            Careers
          </li>
        </ul>
      </nav>

      {/* <div className="main_newblock about-new-top products-details-blocks">
        <div className={stickyClass}>
          <div className="container">
            <ul>
              <li>
                <a href="#about-info">About</a>
              </li>
              <li>
                <a href="#how-it-works">How It Works</a>
              </li>
              <li>
                <a href="#courses">Courses</a>
              </li>
              <li>
                <a href="#instructors">Instructors</a>
              </li>
              <li>
                <a href="#enrollment-options">Enrollment Options</a>
              </li>
              <li>
                <a href="#fa-questions">FAQ</a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

      <div className="section section-padding pt-5" id="scrollTriggerId">
        <div className="container">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-overview"
              role="tabpanel"
              aria-labelledby="nav-overview-tab"
            >
              <div className="row justify-content-between">
                {subjectDetails.courseOverviewHeading ||
                subjectDetails.courseContentHeading ? (
                  <div className="col-xl-7 col-lg-8 order-xl-1 order-lg-1 order-md-2 order-sm-2">
                    <div className="course-details-wrapper">
                      <div className="course-overview" id="about-info">
                        <h2 className="title">
                          {subjectDetails.courseOverviewHeading}
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: subjectDetails.courseOverviewDescription,
                          }}
                        ></div>
                      </div>
                      {subjectDetails.whatYouWillLearnListText.length > 1 ? (
                        <div className="course-learn-list">
                          <h3 className="title">
                            {subjectDetails.whatYouWillLearnHeading}
                          </h3>
                          {subjectDetails.whatYouWillLearnListText ? (
                            <ul>
                              {subjectDetails.whatYouWillLearnListText.map(
                                (list, index) => (
                                  <li key={index}>{list}</li>
                                )
                              )}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {subjectDetails.courseContentHeading ? (
                        <div className="course-lessons">
                          <div className="lessons-top">
                            <h3 className="title">
                              {subjectDetails.courseContentHeading}
                            </h3>
                            <div className="lessons-time">
                              <span>
                                {subjectDetails.courseContentLessonsCount}
                              </span>
                              <span>
                                {subjectDetails.courseContentLessionsTime}
                              </span>
                            </div>
                          </div>
                          {contentList ? (
                            <Accordion defaultActiveKey="0">
                              {contentList.map((list, index) => (
                                <Accordion.Item
                                  eventKey={index + 1}
                                  key={index + 1}
                                >
                                  <Accordion.Header>
                                    {list.courseContentListTitle}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          list.courseContentListDescription,
                                      }}
                                    ></div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          ) : (
                            ""
                          )}
                          <div className="info-text-wrapper mt-4">
                            <h3 className="title">
                              {subjectDetails.courseContentSectionOneHeading}
                            </h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  subjectDetails.courseOverviewDescription,
                              }}
                            ></div>
                          </div>
                          <div className="info-text-wrapper mt-4">
                            <h3 className="title">
                              {subjectDetails.courseContentSectionTwoHeading}
                            </h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  subjectDetails.courseContentSectionTwoDescription,
                              }}
                            ></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-lg-4 order-xl-2  order-lg-2 order-md-1 order-sm-1 mt-3">
                  <div className="sidebar-details-wrap">
                    {subjectDetails.subjectPrice ? (
                      <div className="sidebar-details-video-description">
                        <div className="sidebar-video">
                          <img
                            src={subjectDetails.thumbnailImage}
                            alt="video-thumbnail"
                          />
                          <button
                            onClick={openModal}
                            className="play videoLightbox"
                            style={{ marginLeft: "0", border: "none" }}
                          >
                            <i className="fa fa-play"></i>
                          </button>
                        </div>
                        <VideoModal
                          show={showModal}
                          onHide={closeModal}
                          videoLink={subjectDetails.videoLink}
                        />

                        <div className="sidebar-description">
                          <div className="price">
                            <span className="sale-price">
                              Rs. {subjectDetails.subjectPrice}{" "}
                            </span>
                            <span className="regular-price"></span>
                          </div>
                          <div className="courses-btn">
                            {stringExists ? (
                              <>
                                {subjectDetails.isAllowSubject &&
                                subjectDetails.isAllowSubject ? (
                                  <button
                                    onClick={handleEnrollNow}
                                    className="btn btn-outline-primary enterprise-twobtn"
                                  >
                                    <i className="fa fa-user-plus"></i>&nbsp;
                                    Enroll Now
                                  </button>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                {subjectDetails.isAllowSubject &&
                                subjectDetails.isAllowSubject ? (
                                  <>
                                    {inCart(subjectDetails.decodeSubjectId) ? (
                                      <>
                                        <Link
                                          to={"/cart"}
                                          className="btn btn-warning enterprise-twobtn"
                                        >
                                          <i className="fa fa-shopping-cart"></i>
                                          &nbsp; Go to cart
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          onClick={handleBuyItNow}
                                          className="btn btn-primary btn-hover-heading-color"
                                        >
                                          Buy it now
                                        </button>
                                        <button
                                          onClick={handleAddToCart}
                                          className="btn btn-outline-primary enterprise-twobtn"
                                        >
                                          <i
                                            className="fa fa-shopping-cart"
                                            style={{ color: "#888b88" }}
                                          ></i>
                                          &nbsp; Add To Cart
                                        </button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                          {/* <div className="courses-btn"> */}
                          <Link
                            to="/available-batches"
                            className="btn btn-primary btn-hover-heading-color w-100"
                          >
                            Availabile Batches
                          </Link>
                          {/* </div> */}
                          <ul className="description-list">
                            {subjectDetails.courseDuration ? (
                              <li>
                                <i className="fa fa-clock-o"></i> Duration
                                <span>
                                  {subjectDetails.courseDuration}&nbsp;
                                  {subjectDetails.courseDurationIn}
                                </span>
                              </li>
                            ) : (
                              ""
                            )}
                            {subjectDetails.courseLevel ? (
                              <li>
                                <i className="fa fa-sliders"></i> Level
                                <span>{subjectDetails.courseLevel}</span>
                              </li>
                            ) : (
                              ""
                            )}
                            {subjectDetails.courseLectures ? (
                              <li>
                                <i className="fa fa-file-o"></i> Lectures
                                <span>{subjectDetails.courseLectures}</span>
                              </li>
                            ) : (
                              ""
                            )}
                            {subjectDetails.courseLanguage ? (
                              <li>
                                <i className="fa fa-language"></i> Language
                                <span>{subjectDetails.courseLanguage}</span>
                              </li>
                            ) : (
                              ""
                            )}
                            <li>
                              <i className="fa fa-user-o"></i> Enrolled
                              <span>100+ Enrolled</span>
                            </li>
                            {subjectDetails.courseVideos ? (
                              <li>
                                <i className="fa fa-video-camera"></i> Recorded
                                Video
                                <span>{subjectDetails.courseVideos}</span>
                              </li>
                            ) : (
                              ""
                            )}
                            {subjectDetails.courseTheorys ? (
                              <li>
                                <i className="fa fa-file-text-o"></i> Notes
                                <span>{subjectDetails.courseTheorys}</span>
                              </li>
                            ) : (
                              ""
                            )}
                            {subjectDetails.courseMCQs ? (
                              <li>
                                <i className="fa fa-list"></i> MCQs
                                <span>{subjectDetails.courseMCQs}</span>
                              </li>
                            ) : (
                              ""
                            )}
                            {subjectDetails.coursePPTs ? (
                              <li>
                                <i className="fa fa-file"></i> PPTs
                                <span>{subjectDetails.coursePPTs}</span>
                              </li>
                            ) : (
                              ""
                            )}
                            {subjectDetails.courseLiveSeries ? (
                              <li>
                                <i className="fa fa-desktop"></i> Live Test
                                Series
                                <span>{subjectDetails.courseLiveSeries}</span>
                              </li>
                            ) : (
                              ""
                            )}
                            {subjectDetails.courseEbooks ? (
                              <li>
                                <i className="fa fa-book"></i> E-Book
                                <span>{subjectDetails.courseEbooks}</span>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>

                          <a className="w-100 mb-2" href="/">
                            <i className="fa fa-share"></i> Share This Course
                          </a>
                          <ul className="social-box">
                            <li className="twitter">
                              <i className="fa fa-twitter"> </i>
                            </li>
                            <li className="linkedin">
                              <i className="fa fa-linkedin"> </i>
                            </li>
                            <li className="facebook">
                              <i className="fa fa-facebook-f"> </i>
                            </li>
                            <li className="pinterest">
                              <i className="fa fa-pinterest-p"> </i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {relatedSubjectList ? (
                      <div className="sidebar-details-course">
                        <h4 className="sidebar-details-title">
                          Releted Courses
                        </h4>
                        <ul className="sidebar-details-courses">
                          {relatedSubjectList.map((relatedList, index) => (
                            <li key={index}>
                              <div className="sidebar-course-item">
                                <div className="item-image">
                                  <Link
                                    to={`/courses/${relatedList.courseNameSlugUrl}/${relatedList.subjectNameSlugUrl}`}
                                  >
                                    <img
                                      src={relatedList.subjectPicture}
                                      alt={relatedList.subjectName}
                                    />
                                  </Link>
                                </div>
                                <div className="item-content">
                                  <h3 className="title">
                                    <Link
                                      to={`/courses/${relatedList.courseNameSlugUrl}/${relatedList.subjectNameSlugUrl}`}
                                    >
                                      {relatedList.subjectName}
                                    </Link>
                                  </h3>
                                  <span className="price">
                                    <strong>
                                      Rs. {relatedList.subjectPrice}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {subjectDetails.professionalCertificateHeading &&
                subjectDetails.professionalCertificateDescription &&
                subjectDetails.professionalCertificateImage ? (
                  <div className="col-md-12 mt-5 order-3" id="how-it-works">
                    <div className="course-overview">
                      <h3 className="title">
                        {subjectDetails.professionalCertificateHeading}
                      </h3>
                      <div className="row mt-3">
                        <div className="col-md-7">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                subjectDetails.professionalCertificateDescription,
                            }}
                          ></div>
                        </div>
                        <div className="col-md-5">
                          <img
                            src={subjectDetails.professionalCertificateImage}
                            alt={subjectDetails.professionalCertificateHeading}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {subjectStructureList ? (
                  <div
                    className="col-md-12 mt-5 professional-certificate order-3"
                    id="courses"
                  >
                    <h3 className="title mb-4">Course Structure </h3>
                    {subjectStructureList.map((list, index) => (
                      <div className="course-instructor" key={index + 1}>
                        <div className="instructor-profile">
                          <div className="profile-images text-center">
                            <span>
                              <h2>{index + 1}</h2>
                            </span>
                          </div>
                          <div className="profile-content">
                            <h5 className="name">
                              {index + 1}. {list.syllabusHeading}
                            </h5>
                            <div className="profile-meta">
                              <div className="rating">
                                <div className="rating-star">
                                  <div
                                    className="rating-active"
                                    style={{
                                      width: `${Math.round(
                                        list.syllabusStarRating * 2
                                      )}0%`,
                                    }}
                                  ></div>
                                </div>
                                <span>{list.syllabusStarRating}</span>
                              </div>
                              <span className="meta-action">
                                <i className="fa fa-play-circle text-dark"></i>
                                &nbsp;
                                {list.syllabusRating} ratings
                              </span>
                              <span className="meta-action">
                                <i className="fa fa-user-o text-dark"></i>&nbsp;
                                {list.syllabusNoOfReviews} reviews
                              </span>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: list.syllabusDescription,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}

                {startLearningData.points ? (
                  <div
                    className="col-md-12 mt-0 course-details-wrapper order-4"
                    id="enrollment-options"
                  >
                    <div className="row course-learn-list mt-0">
                      <div className="col-md-7">
                        <h3>{startLearningData.heading}</h3>
                        <h6>{startLearningData.subHeading}</h6>
                        <ul>
                          {startLearningData.points.map((points, index) => (
                            <li key={index}>{points}</li>
                          ))}
                        </ul>
                      </div>

                      <div className="col-md-5">
                        <div className="share-certficate">
                          <h5>Shareable on</h5>
                          <ul className="social-box">
                            <li className="twitter">
                              <i className="fa fa-twitter">{""}</i>
                            </li>
                            <li className="linkedin">
                              <i className="fa fa-linkedin">{""}</i>
                            </li>
                            <li className="facebook">
                              <i className="fa fa-facebook-f">{""}</i>
                            </li>
                            <li className="instagram">
                              <i className="fa fa-instagram">{""}</i>
                            </li>
                            <li className="quora">
                              <i className="fa fa-quora">{""}</i>
                            </li>
                            <li className="pinterest">
                              <i className="fa fa-pinterest-p">{""}</i>
                            </li>
                          </ul>
                        </div>
                        <img src={startLearningData.image} alt="certificate" />
                        <h6 className="mt-3">
                          {startLearningData.imageDescription}
                        </h6>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {subjectInstructorList ? (
                  subjectInstructorList.length > 0 ? (
                    <div className="col-md-12 mt-5 order-5" id="instructors">
                      <p className="title text-primary">
                        Generative AI Courses Instructors
                      </p>
                      <h2 className="mt-2 fw-semibold">
                        Whom will you learn from?
                      </h2>

                      <div className="d-flex my-3 align-items-center gap-3">
                        <div className="left">
                          <lord-icon
                            src="https://cdn.lordicon.com/gkgkfbzm.json"
                            trigger="loop"
                            style={{ width: "70px", height: "70px" }}
                          ></lord-icon>
                        </div>
                        <div className="right">
                          <h2>{subjectInstructorList.length}</h2>
                          <p>Industry Experts</p>
                        </div>
                      </div>

                      <h4 className="mt-3 mb-3 fw-semibold text-danger text-decoration-underline">
                        Industry Experts
                      </h4>
                      {subjectInstructorList.length > 3 ? (
                        <OwlCarousel
                          items={5}
                          className="owl-theme fetured_slide owl-loaded owl-drag"
                          loop
                          margin={20}
                          autoplay={true}
                          dots={true}
                          responsive={responsive1}
                          autoplayTimeout={2000} // Adjust autoplay speed (in milliseconds)
                          // autoplayHoverPause={true}
                          navSpeed={500} // Adjust loop speed (in milliseconds)
                        >
                          {subjectInstructorList.map((item, index) => (
                            <div className="item" key={index + 1}>
                              <Card className="course-instructor mb-5 p-3">
                                <div className="mt-0">
                                  <div className="d-flex gap-4">
                                    <div
                                      className="profile-images"
                                      style={{
                                        width: "64px",
                                        height: "64px",
                                      }}
                                    >
                                      <img
                                        src={
                                          item.profilePicture
                                            ? item.profilePicture
                                            : imgPlaceholder
                                        }
                                        alt="instructor"
                                        loading="lazy"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: "50%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <h5 className="name mt-2">
                                        {item.fullName ? item.fullName : ""}
                                      </h5>
                                      <a
                                        href="#"
                                        style={{
                                          width: "70px",
                                          height: "40px",
                                        }}
                                        className="mb-3"
                                      >
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "contain",
                                          }}
                                          src={
                                            item.companyLogo
                                              ? item.companyLogo
                                              : imgPlaceholder
                                          }
                                          alt="company-logo"
                                          loading="lazy"
                                        />
                                      </a>
                                    </div>
                                  </div>

                                  <div className="profile-content">
                                    <p className="mb-2">
                                      <i className="ri-verified-badge-line text-danger me-2"></i>
                                      {item.designation ? item.designation : ""}
                                    </p>
                                    <p>
                                      <i className="ri-survey-line text-danger me-2"></i>
                                      <TruncatedParagraph text={item.bio} />
                                      {/* {truncatedText}
                                    {shouldShowReadMore && (
                                      <button
                                        className="btn btn-link fs-6 p-0 ms-1"
                                        style={{
                                          lineHeight: "0",
                                          height: "20px",
                                        }}
                                        onClick={toggleTruncate}
                                      >
                                        {isTruncated
                                          ? "... Read more"
                                          : " Show less"}
                                      </button>
                                    )} */}
                                    </p>
                                    <a
                                      href={
                                        item.linkedinUrl
                                          ? item.linkedinUrl
                                          : "#"
                                      }
                                      style={{ width: "70px", height: "40px" }}
                                    >
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                        src={instructorLinkedIn}
                                        alt="linkedin-logo"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          ))}
                        </OwlCarousel>
                      ) : (
                        <div className="row">
                          {subjectInstructorList.map((item, index) => (
                            <div className="item col-md-4" key={index + 1}>
                              <Card className="course-instructor mb-5 p-3">
                                <div className="mt-0">
                                  <div className="d-flex gap-4">
                                    <div
                                      className="profile-images"
                                      style={{
                                        width: "64px",
                                        height: "64px",
                                      }}
                                    >
                                      <img
                                        src={
                                          item.profilePicture
                                            ? item.profilePicture
                                            : imgPlaceholder
                                        }
                                        alt="instructor"
                                        loading="lazy"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: "50%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <h5 className="name mt-2">
                                        {item.fullName ? item.fullName : ""}
                                      </h5>
                                      <a
                                        href="#"
                                        style={{
                                          width: "70px",
                                          height: "40px",
                                        }}
                                        className="mb-3"
                                      >
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "contain",
                                          }}
                                          src={
                                            item.companyLogo
                                              ? item.companyLogo
                                              : imgPlaceholder
                                          }
                                          alt="company-logo"
                                          loading="lazy"
                                        />
                                      </a>
                                    </div>
                                  </div>

                                  <div className="profile-content">
                                    <p className="mb-2">
                                      <i className="ri-verified-badge-line text-danger me-2"></i>
                                      {item.designation ? item.designation : ""}
                                    </p>
                                    <p>
                                      <i className="ri-survey-line text-danger me-2"></i>
                                      <TruncatedParagraph text={item.bio} />
                                      {/* {truncatedText}
                                  {shouldShowReadMore && (
                                    <button
                                      className="btn btn-link fs-6 p-0 ms-1"
                                      style={{
                                        lineHeight: "0",
                                        height: "20px",
                                      }}
                                      onClick={toggleTruncate}
                                    >
                                      {isTruncated
                                        ? "... Read more"
                                        : " Show less"}
                                    </button>
                                  )} */}
                                    </p>
                                    <a
                                      href={
                                        item.linkedinUrl
                                          ? item.linkedinUrl
                                          : "#"
                                      }
                                      style={{ width: "70px", height: "40px" }}
                                    >
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                        src={instructorLinkedIn}
                                        alt="linkedin-logo"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          ))}
                        </div>
                      )}
                      {/* <div className="row">
                    {subjectInstructorList.map((instructors, index) => (
                      <div className="col-md-6" key={index}>
                        <div className="course-instructor mb-5">
                          <div className="instructor-profile">
                            <div className="profile-images">
                              <img
                                src={instructors.profilePicture}
                                alt="instructor"
                                loading="lazy"
                              />
                            </div>
                            <div className="profile-content">
                              <h5 className="name">{instructors.fullName}</h5>
                              <div className="profile-meta">
                                <div className="rating">
                                  <div className="rating-star">
                                    <div
                                      className="rating-active"
                                      style={{
                                        width: `${Math.round(
                                          instructors.starRating * 2
                                        )}0%`,
                                      }}
                                    ></div>
                                  </div>
                                  <span>({instructors.starRating})</span>
                                </div>
                                <span className="meta-action">
                                  <i className="fa fa-play-circle"></i>
                                  {instructors.noOfTutorial} Tutorials
                                </span>
                                <span className="meta-action">
                                  <i className="fa fa-user-o"></i>
                                  {instructors.noOfStudents} Students
                                </span>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    instructors.courseInstructorDescription,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div> */}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                <div className="col-md-12 mt-5 order-5">
                  {subjectVideosList ? (
                    <div className="col-md-12">
                      <section className="course_single_video">
                        {subjectVideosList.length > 4 ? (
                          <OwlCarousel
                            items={5}
                            className="owl-theme fetured_slide owl-loaded owl-drag"
                            loop
                            margin={20}
                            autoplay={true}
                            dots={false}
                            responsive={responsive}
                            autoplayTimeout={2000} // Adjust autoplay speed (in milliseconds)
                            autoplayHoverPause={true}
                            navSpeed={500} // Adjust loop speed (in milliseconds)
                          >
                            {subjectVideosList.map((videoList, index) => (
                              <div key={index} className="item">
                                <div className="single-course flex-fill">
                                  <div className="courses-image">
                                    <div className="course-tag">
                                      <p>Academic</p>
                                    </div>
                                    <a href={videoList.embedLink} className="">
                                      <img
                                        src={videoList.thumbnailImage}
                                        alt={index}
                                        loading="lazy"
                                      />
                                    </a>
                                    <button
                                      type="button"
                                      className="btn btn-primary video-btn"
                                      onClick={() =>
                                        openCourseModal(videoList.embedLink)
                                      }
                                    >
                                      <i className="fa fa-play"></i>
                                    </button>
                                  </div>
                                  <div className="courses-content">
                                    <div className="top-meta">
                                      <a
                                        className="tag"
                                        href="javascript:void(0);"
                                      >
                                        {videoList.subjectName}
                                      </a>
                                      <span className="price">
                                        <span className="sale-price">
                                          {videoList.subjectPrice}
                                        </span>
                                      </span>
                                    </div>
                                    <p className="author-name">
                                      {videoList.departmentName}
                                    </p>
                                    <span className="course-name-tag">
                                      {videoList.courseName}
                                    </span>
                                  </div>
                                  <div className="courses-meta">
                                    <div className="course-new-list-outer">
                                      <div className="course-new-list-inner">
                                        <i className="fa fa-book"></i>
                                        <p>
                                          {videoList.courseContentLessonsCount}
                                        </p>
                                      </div>
                                      <div className="course-new-list-inner">
                                        <i className="fa fa-users"></i>
                                        <p>100 Seats</p>
                                      </div>
                                      <div className="course-new-list-inner">
                                        <i className="fa fa-calendar"></i>
                                        <p>
                                          {videoList.courseDuration}{" "}
                                          {videoList.courseDurationIn}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="add-btn">
                                      <p className="add-cart-btn">
                                        {videoList.videoName}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </OwlCarousel>
                        ) : (
                          <>
                            <div className="row">
                              {subjectVideosList.map((videoList, index) => (
                                <div key={index} className="item col-md-3">
                                  <div className="single-course flex-fill">
                                    <div className="courses-image">
                                      <div className="course-tag">
                                        <p>Academic</p>
                                      </div>
                                      <a
                                        href={videoList.embedLink}
                                        className=""
                                      >
                                        <img
                                          src={videoList.thumbnailImage}
                                          alt={index}
                                          loading="lazy"
                                        />
                                      </a>
                                      <button
                                        type="button"
                                        className="btn btn-primary video-btn"
                                        onClick={() =>
                                          openCourseModal(videoList.embedLink)
                                        }
                                      >
                                        <i className="fa fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="courses-content">
                                      <div className="top-meta">
                                        <a
                                          className="tag"
                                          href="javascript:void(0);"
                                        >
                                          {videoList.subjectName}
                                        </a>
                                        <span className="price">
                                          <span className="sale-price">
                                            {videoList.subjectPrice}
                                          </span>
                                        </span>
                                      </div>
                                      <p className="author-name">
                                        {videoList.departmentName}
                                      </p>
                                      <span className="course-name-tag">
                                        {videoList.courseName}
                                      </span>
                                    </div>
                                    <div className="courses-meta">
                                      <div className="course-new-list-outer">
                                        <div className="course-new-list-inner">
                                          <i className="fa fa-book"></i>
                                          <p>
                                            {
                                              videoList.courseContentLessonsCount
                                            }
                                          </p>
                                        </div>
                                        <div className="course-new-list-inner">
                                          <i className="fa fa-users"></i>
                                          <p>100 Seats</p>
                                        </div>
                                        <div className="course-new-list-inner">
                                          <i className="fa fa-calendar"></i>
                                          <p>
                                            {videoList.courseDuration}{" "}
                                            {videoList.courseDurationIn}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="add-btn">
                                        <p className="add-cart-btn">
                                          {videoList.videoName}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </section>
                    </div>
                  ) : (
                    ""
                  )}
                  <CourseVideoModal
                    show={showCourseModal}
                    onHide={closeCourseModal}
                    videoLink={cardVideoLink}
                  />

                  {subjectFaqsList ? (
                    <section
                      className="terms-qutaion frequently-asked-questions"
                      id="fa-questions"
                    >
                      <h3 className="mb-3">Frequently Asked Questions</h3>
                      <div
                        className="panel-group accordion"
                        id="another"
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="panel panel-default">
                          <Accordion defaultActiveKey="0">
                            {subjectFaqsList.map((list, index) => (
                              <Accordion.Item
                                eventKey={index + 1}
                                key={index + 1}
                              >
                                <Accordion.Header>
                                  {list.question}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: list.answer,
                                    }}
                                  ></div>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}

                  {subjectReviewRatingList.averageRating ? (
                    <div className="col-md-9 mt-5 order-8 mx-auto">
                      <div className="course-review">
                        <h3 className="title">Review</h3>
                        <div className="review-rating">
                          <div className="rating-box">
                            <span className="count">
                              {subjectReviewRatingList.averageRating}
                            </span>
                            <div className="rating-star">
                              <div
                                className="rating-active"
                                style={{
                                  width: `${Math.round(
                                    subjectReviewRatingList.averageRating * 2
                                  )}0%`,
                                }}
                              ></div>
                            </div>
                            <p>
                              Total {subjectReviewRatingList.totalRatingRecord}{" "}
                              Ratings
                            </p>
                          </div>
                          <div className="rating-percentage">
                            <div className="single-rating-percentage">
                              <span className="label">5 Star</span>
                              <div className="rating-line">
                                <div className="line-bar"></div>
                              </div>
                            </div>
                            <div className="single-rating-percentage">
                              <span className="label">4 Star</span>
                              <div className="rating-line">
                                <div className="line-bar"></div>
                              </div>
                            </div>
                            <div className="single-rating-percentage">
                              <span className="label">3 Star</span>
                              <div className="rating-line">
                                <div className="line-bar"></div>
                              </div>
                            </div>
                            <div className="single-rating-percentage">
                              <span className="label">2 Star</span>
                              <div className="rating-line">
                                <div className="line-bar"></div>
                              </div>
                            </div>
                            <div className="single-rating-percentage">
                              <span className="label">1 Star</span>
                              <div className="rating-line">
                                <div className="line-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {subjectReviewRatingList.reviewList ? (
                          <div className="review-items">
                            <ul>
                              {subjectReviewRatingList.reviewList.map(
                                (review, index) => (
                                  <li key={index}>
                                    <div className="single-review">
                                      <div className="review-author">
                                        <img
                                          src={review.profilePicture}
                                          alt={review.reviewRatingId}
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="review-content">
                                        <div className="review-top">
                                          <h4 className="name">
                                            {review.fullName}
                                          </h4>
                                          <div className="rating-star">
                                            <div
                                              className="rating-active"
                                              style={{
                                                width: `${Math.round(
                                                  review.rating * 2
                                                )}0%`,
                                              }}
                                            >
                                              {review.rating}
                                            </div>
                                          </div>
                                          <span className="date">
                                            {review.reviewDate}
                                          </span>
                                        </div>
                                        <h6>{review.userType}</h6>
                                        <p>{review.message}</p>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-8 mt-5 comment-form order-6 mx-auto">
                    <hr />
                    <div className="group-title mb-3">
                      <h5>Leave A Comment</h5>
                    </div>
                    <form
                      action="http://localhost/matriyeacademy/user-review/store"
                      className="form-horizontal"
                      method="post"
                      id="frmReviewRatingStore"
                      name="frmReviewRatingStore"
                      acceptCharset="utf-8"
                      noValidate="novalidate"
                    >
                      <input
                        type="hidden"
                        name="subjectId"
                        defaultValue="VmtaYVJrOVdRbEpRVkRBOQ=="
                      />
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <div className="star-rating">
                            <input
                              type="radio"
                              id="5-stars"
                              name="rating"
                              defaultValue="5"
                            />
                            <label htmlFor="5-stars" className="star">
                              ★
                            </label>
                            <input
                              type="radio"
                              id="4-stars"
                              name="rating"
                              defaultValue="4"
                            />
                            <label htmlFor="4-stars" className="star">
                              ★
                            </label>
                            <input
                              type="radio"
                              id="3-stars"
                              name="rating"
                              defaultValue="3"
                            />
                            <label htmlFor="3-stars" className="star">
                              ★
                            </label>
                            <input
                              type="radio"
                              id="2-stars"
                              name="rating"
                              defaultValue="2"
                            />
                            <label htmlFor="2-stars" className="star">
                              ★
                            </label>
                            <input
                              type="radio"
                              id="1-star"
                              name="rating"
                              defaultValue="1"
                            />
                            <label htmlFor="1-star" className="star">
                              ★
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            className="darma"
                            name="message"
                            placeholder="Your Message"
                          ></textarea>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            data-toggle="modal"
                            data-target="#login"
                          >
                            Write A Review
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-admission"
              role="tabpanel"
              aria-labelledby="nav-admission-tab"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: subjectDetails.admissionContent,
                }}
              ></div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-academics"
              role="tabpanel"
              aria-labelledby="nav-academics-tab"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: subjectDetails.academicsContent,
                }}
              ></div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-tution-financing"
              role="tabpanel"
              aria-labelledby="nav-tution-financing-tab"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: subjectDetails.tutionFinancingContent,
                }}
              ></div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-student-experience"
              role="tabpanel"
              aria-labelledby="nav-student-experience-tab"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: subjectDetails.studentExperienceContent,
                }}
              ></div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-university"
              role="tabpanel"
              aria-labelledby="nav-university-tab"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: subjectDetails.aboutUniversityContent,
                }}
              ></div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-careers"
              role="tabpanel"
              aria-labelledby="nav-careers-tab"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: subjectDetails.careersContent,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ERPCourseDetails;
