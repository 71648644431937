import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const News = () => {
  const newsData = [
    {
      featuredInId: "46",
      imagePath:
        "https://admin.cosmosiq.ai/apis/FileServer/Website/Home/imagePath/668e829a78d86.webp",
      redirectionURL:
        "https://asiannews.in/presenting-cosmosiq-ai-by-matriye-transforming-the-edtech-universe-in-2024/",
    },
    {
      featuredInId: "45",
      imagePath:
        "https://admin.cosmosiq.ai/apis/FileServer/Website/Home/imagePath/668e82409eb1c.webp",
      redirectionURL:
        "https://www.lokmattimes.com/business/presenting-cosmosiqai-by-matriye-transforming-the-edtech-universe-in-2024/",
    },
    {
      featuredInId: "44",
      imagePath:
        "https://admin.cosmosiq.ai/apis/FileServer/Website/Home/imagePath/668e81a5d43f0.webp",
      redirectionURL:
        "https://republicnewstoday.com/index.php/2024/07/02/presenting-cosmosiq-ai-by-matriye-transforming-the-edtech-universe-in-2024/",
    },
    {
      featuredInId: "43",
      imagePath:
        "https://admin.cosmosiq.ai/apis/FileServer/Website/Home/imagePath/668e80a5c504c.webp",
      redirectionURL:
        "https://www.ahmedabadmirror.com/presenting-cosmosiqai-by-matriye-transforming-the-edtech-universe-in-2024/81870479.html#goog_rewarded",
    },
    {
      featuredInId: "42",
      imagePath:
        "https://admin.cosmosiq.ai/apis/FileServer/Website/Home/imagePath/668e80578ec3e.webp",
      redirectionURL:
        "https://news21.co.in/index.php/2024/07/02/presenting-cosmosiq-ai-by-matriye-transforming-the-edtech-universe-in-2024/",
    },
    {
      featuredInId: "41",
      imagePath:
        "https://admin.cosmosiq.ai/apis/FileServer/Website/Home/imagePath/668e7fc8ccb87.webp",
      redirectionURL:
        "https://startupnews.fyi/2024/07/02/presenting-cosmosiq-ai-by-matriye-transforming-the-edtech-universe-in-2024/",
    },
    {
      featuredInId: "40",
      imagePath:
        "https://admin.cosmosiq.ai/apis/FileServer/Website/Home/imagePath/668e7f5548130.webp",
      redirectionURL:
        "https://m.dailyhunt.in/news/india/english/loktej+english-epaper-loktejen/presenting+cosmosiqai+by+matriye+transforming+the+edtech+universe+in+2024-newsid-n620288290?sm=Y",
    },
  ];

  const [responsive, setResponsive] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive = {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    };

    setResponsive(updatedResponsive);
  }, []);

  return (
    <>
      <section className="featured-section">
        <div className="container">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2 className="title text-white fw-semibold">
                  Cosmos IQ in the News
                </h2>
                <p className="text-white fs-5">
                  Sometimes we make the headlines, sometimes our learners do.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <OwlCarousel
                items={14}
                className="owl-theme fetured_slide owl-loaded owl-drag"
                margin={20}
                autoplay={true}
                autoplayHoverPause={false}
                autoplayTimeout={2000}
                smartSpeed={1000}
                loop
                dots={false}
                responsive={responsive}
              >
                {newsData
                  ? newsData.map((featuredIn, index) => (
                      <div key={index} className="item">
                        <a href={featuredIn.redirectionURL} target="_blank">
                          <div className="featured-inner">
                            <img src={featuredIn.imagePath} alt={index} />
                          </div>
                        </a>
                      </div>
                    ))
                  : ""}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
