import {
  officeCulture1,
  officeCulture2,
  officeCulture3,
  officeCulture4,
  officeCulture5,
  officeCulture6,
  officeCulture7,
  officeCulture8,
  officeCulture9,
  officeCulture10,
  officeCulture11,
  officeCulture12,
  officeCulture13,
  officeCulture14,
  officeCulture15,
  officeCulture16,
  officeCulture17,
  officeCulture18,
  officeCulture19,
  officeCulture20,
  officeCulture21,
  officeCulture22,
  officeCulture23,
  officeCulture24,
  officeCulture25,
  officeCulture26,
  officeCulture27,
  officeCulture28,
  officeCulture29,
} from "../../imports/ImageImports";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

const images = [
  {
    src: officeCulture26,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture27,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture28,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture29,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture1,
    alt: "Big Ben - London",
  },
  {
    src: officeCulture2,
    alt: "Big Ben - London",
  },
  {
    src: officeCulture3,
    alt: "Big Ben - London",
  },

  {
    src: officeCulture4,
    alt: "Big Ben - London",
  },
  {
    src: officeCulture5,
    alt: "Big Ben - London",
  },
  {
    src: officeCulture6,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture7,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture8,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture9,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture10,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture11,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture12,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture13,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture14,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture15,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture16,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture17,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture18,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture19,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture20,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture21,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture22,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture23,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture24,
    alt: "Red Zone - Paris",
  },
  {
    src: officeCulture25,
    alt: "Red Zone - Paris",
  },
];

const GalleryGrid = () => {
  const [responsive1, setResponsive1] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive1 = {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    };

    setResponsive1(updatedResponsive1);
  }, []);

  return (
    <>
      <section className="featured-section bg-light">
        <div className="container py-3">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2 className="title">
                  Meet Our Team: Embracing a Culture of{" "}
                  <span className="text-danger">
                    Innovation and Collaboration
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12">
              <OwlCarousel
                items={4}
                className="owl-theme fetured_slide owl-loaded owl-drag"
                margin={10}
                dots={true}
                autoplay={true}
                autoplayHoverPause={false}
                autoplayTimeout={2000}
                smartSpeed={1000}
                loop
                responsive={responsive1}
              >
                {images
                  ? images.map((image, index) => (
                      <div key={index} className="item">
                        <div className="text-center">
                          <div className="">
                            <img
                              src={image.src}
                              alt={image.src}
                              style={{
                                // width: "360px",
                                height: "250px",
                                objectFit: "contain",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  : ""}
              </OwlCarousel>
            </div>
          </div>
          {/* <Gallery enableImageSelection={false} images={images} /> */}
        </div>
      </section>
    </>
  );
};

export default GalleryGrid;
