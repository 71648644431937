import React from "react";
import { Link } from "react-router-dom";

const YoutubeBanner = () => {
  return (
    <>
      <div
        // style={{
        //   height: "500px",
        // }}
        className="section page-banner-section  about-banner gov-banner m-0"
        style={{ padding: "90px 0", backgroundColor: "rgb(64, 81, 137)" }}
      >
        <div className="container">
          <div className="d-flex justify-content-between align-items-center flex-column flex-lg-row gap-4 gap-lg-0">
            <div className="course-details-banner-content">
              <p className="text-danger fs-4 mt-0 mb-2">We are</p>
              <h2
                className="text-white mb-3"
                style={{ letterSpacing: "1.3px" }}
              >
                World's Largest EdTech Company Leading with AI Innovation
              </h2>
              <p className="text-white fs-6">
                With the latest technology, pedagogy, industry partners and
                world-class faculty, we create immersive online learning
                experiences for our learners globally.
              </p>
              <div className="mt-4">
                <button className="btn btn-success btn-hover-heading-color fw-medium">
                  Watch glimpses of impact we have created!
                </button>
              </div>
            </div>
            <iframe
              style={{ width: "100%", maxWidth: "560px", height: "315px" }}
              src="https://www.youtube.com/embed/perNmuGlaZg?si=Q45E926ErFG7emxj&amp;autoplay=1"
              title="YouTube video player"
              allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default YoutubeBanner;
