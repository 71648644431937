import React from "react";
import { Link } from "react-router-dom";

import video from "../../../assets/website/videos/videoTemp2.mp4";

const HeaderBannerNew = () => {
  return (
    <>
      <video
        src={video}
        autoPlay={true}
        muted={true}
        loop={true}
        style={{
          height: "500px",
          objectFit: "cover",
          objectPosition: "center",
          opacity: "40%",
          zIndex: "0",
          position: "relative",
        }}
        className="section page-banner-section bg-color-1 about-banner gov-banner p-0 m-0"
      ></video>
      <div className="container" style={{ position: "absolute", top: "180px" }}>
        <div
          className="course-details-banner-content ps-0 ps-md-5"
          style={{ maxWidth: "700px" }}
        >
          <p className="text-danger fs-4">About Us</p>
          <h2 className="" style={{ letterSpacing: "1.3px" }}>
            We imagine a world where everyone, no matter where they are, has the
            ability to transform their lives through education.
          </h2>
          <div className="courses-btn mt-3">
            <Link className="btn btn-primary btn-hover-heading-color" to="/">
              Know more
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBannerNew;
