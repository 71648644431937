import { useEffect, useState } from "react";
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Offcanvas from "react-bootstrap/Offcanvas";
function PromoCodeModal({ showModal, onClose, promoCodesData }) {
  const [show, setShow] = useState(false);
  const [copiedStates, setCopiedStates] = useState(
    Array(promoCodesData.length).fill(false)
  );

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  const handleCopyClick = (code, index) => {
    const newCopiedStates = [...copiedStates];
    newCopiedStates[index] = true;
    setCopiedStates(newCopiedStates);
    handleClose();
    toast.info("Copied", {
      autoClose: 5000,
      theme: "colored",
    });
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Promocode</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="promoCodeList">
            {/* <p className="px-3 text-success fs-15">Click a code to copy</p> */}
            <ul className="px-3">
              {promoCodesData.length > 0 ? (
                <>
                  {promoCodesData.map((promo, index) => (
                    <CopyToClipboard
                      key={index}
                      text={promo.couponCode}
                      onCopy={() => handleCopyClick(promo.couponCode, index)}
                    >
                      <li
                        style={{ cursor: "pointer" }}
                        key={index}
                        className="py-1 border px-2 my-2 d-flex justify-content-between"
                      >
                        <div>
                          <h5 className="py-1">Get {promo.couponPrice} OFF</h5>
                          <span
                            style={{
                              fontSize: "13px",
                              paddingRight: "20px",
                              color: "gray",
                            }}
                          >
                            {promo.couponDescription}
                          </span>
                        </div>
                        <span>
                          {copiedStates[index] ? <FaCheck /> : <FaRegCopy />}
                        </span>
                      </li>
                    </CopyToClipboard>
                  ))}
                </>
              ) : (
                <li
                  style={{ cursor: "pointer" }}
                  className="py-1 border px-2 my-2 d-flex justify-content-between"
                >
                  <div>
                    <h5 className="py-1">Promo codes is not available</h5>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default PromoCodeModal;
