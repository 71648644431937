import React from "react";

const CareerInfoBlock = (props) => {
  const openPDF = () => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.document.body.innerHTML = `
        <iframe
          src="${props.data.brochuresPDF}"
          style="width: 100vw; height: 100vh; border: none;"
        ></iframe>
      `;
    } else {
      // Handle if pop-up blocked
      console.error("Pop-up blocked. Please allow pop-ups for this site.");
    }
  };

  return (
    <>
      <section className="about-us-trial">
        <div className="container">
          <div className="feature-intro space-top text-left">
            <div className="row align-items-center">
              <div className="col-md-12">
                <h2 className="heading-section">{props.data.beliverHeading}</h2>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={props.data.beliverImageOne}
                      alt="career-planning"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-6">
                    <img
                      src={props.data.beliverImageTwo}
                      alt="career-planning"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <img
                      src={props.data.beliverImageThree}
                      className="img-fluid"
                      alt="bg-1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="heading-section passionate">
                  {props.data.beliverSubHeading}
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.data.beliverDescription,
                  }}
                ></div>
                <div className="recruiters-profile ">
                  <h3 className="mb-3">Recruiters Profile</h3>
                  <div className="d-flex gap-4 align-items-center">
                    <div
                      className="img-container"
                      style={{ width: "175px", height: "60px" }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src="https://sm.pcmag.com/pcmag_uk/review/z/zoho-recru/zoho-recruit_cegr.png"
                        alt="bg-2"
                      />
                    </div>
                    <a
                      className="btn btn-outline-primary mt-0 w-auto"
                      href="https://matriyeacademyus.zohorecruit.in/jobs/Careers"
                      target="_blank"
                    >
                      Click here to apply
                    </a>
                  </div>
                  <div className="d-flex gap-4 align-items-center mt-3">
                    <div
                      className="img-container"
                      style={{ width: "175px", height: "60px" }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src="https://www.matriyeacademy.com/website-assets/images/linkedin.png"
                        alt="bg-2"
                      />
                    </div>
                    <a
                      className="btn btn-outline-primary mt-0 w-auto"
                      href="https://www.linkedin.com/company/cosmos-iq-ai"
                      target="_blank"
                    >
                      Click here to apply
                    </a>
                  </div>

                  {/* <div className="mt-3">
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/linkedin.png"
                      alt="bg-3"
                    />
                    <a
                      className="btn btn-outline-primary"
                      href="https://www.linkedin.com/company/cosmos-iq-ai"
                    >
                      Click here to apply
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-md-12">
                <div className="wrapper-brochures">
                  <div className="brochures-down-load">
                    <h4>{props.data.brochuresHeading}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.data.brochuresDescription,
                      }}
                    ></div>
                  </div>
                  <div className="download-btn">
                    <ul className="files">
                      <li>
                        <button className="btn btn-primary" onClick={openPDF}>
                          {props.data.brochuresButtonName}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerInfoBlock;
